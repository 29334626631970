import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    DialogTitle as MuiDialogTitle,
    DialogContent,
    Typography,
    withStyles,
    IconButton,
    Theme,
    createStyles,
    WithStyles,
    TextField,
    Chip
} from '@material-ui/core';
import { RootState } from '../../../redux/store';
import { connect, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import { useCookies } from 'react-cookie';
import {
    CreateBannerData,
    GetBannerData,
    UpdateBannerData,
    DeleteBannerData
} from '../../../redux/Users/actions';
import { CommonDispatcher } from '../../../redux/Common/action';
import { ADD_BANNER_MODAL } from '../../../redux/ModalReducer/actionTypes';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const AddBannerModal = ({
    CreateBannerData,
    GetBannerData,
    UpdateBannerData,
    DeleteBannerData,
    CommonDispatcher
}) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.addBannerModal) || {};
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ id: null, text: '', url: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    useEffect(() => {
        setIsLoading(true);
        GetBannerData(token).then((d) => {
            setItems(d?.response?.banners || []);
            setIsLoading(false);
        });
    }, []);

    const handleEdit = (item) => {
        setFormData(item); // Populate input fields with the selected item's values
        setIsEdit(true);
        setOpen(true);
    };

    const handleClose = () => {
        CommonDispatcher(ADD_BANNER_MODAL, { isOpen: false });
        setFormData({ id: null, text: '', url: '' });
        setOpen(false);
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            if (isEdit) {
                UpdateBannerData(token, {
                    id: formData.id,
                    text: formData.text,
                    url: formData.url
                }).then((response) => {
                    console.log('response', response);
                    setItems((prevItems) =>
                        prevItems.map((item) =>
                            item.id === formData.id
                                ? {
                                      ...item,
                                      id: formData.id,
                                      text: formData.text,
                                      url: formData.url
                                  }
                                : item
                        )
                    );
                });
            } else {
                CreateBannerData(token, {
                    text: formData.text,
                    url: formData.url
                }).then((response) => {
                    setItems((prevItems) => [
                        ...prevItems.map((item) => ({
                            ...item,
                            is_enabled: false
                        })),
                        response.response
                    ]);
                });
            }
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setIsLoading(false);
            setIsEdit(false);
            setFormData({ id: null, text: '', url: '' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Dialog
            className="w-100"
            maxWidth="sm"
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                Banner
            </DialogTitle>
            <DialogContent style={{ minHeight: '400px', height: '100%' }}>
                <TextField
                    autoFocus
                    margin="dense"
                    name="text"
                    label="Text"
                    fullWidth
                    value={formData.text}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="url"
                    label="Url"
                    fullWidth
                    value={formData.url}
                    onChange={handleChange}
                />
                <div className="mt-2 d-flex justify-content-end">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSave()}
                    >
                        {isEdit ? 'Update Item' : 'Add Item'}
                    </Button>
                </div>
                {isLoading ? (
                    <div className="loader" />
                ) : (
                    <TableContainer
                        component={Paper}
                        style={{ marginTop: '20px' }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Active</TableCell>

                                    <TableCell>Name</TableCell>
                                    <TableCell>Url</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items
                                    .sort((a, b) => {
                                        if (a.is_enabled === b.is_enabled) {
                                            return 0;
                                        }
                                        return a.is_enabled ? -1 : 1;
                                    })
                                    .map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell>
                                                {' '}
                                                <Chip
                                                    label={
                                                        item.is_enabled
                                                            ? 'ACTIVE'
                                                            : '  INACTIVE'
                                                    }
                                                    color={
                                                        item.is_enabled
                                                            ? 'secondary'
                                                            : 'default'
                                                    }
                                                />
                                                {}
                                            </TableCell>
                                            <TableCell>{item.text}</TableCell>
                                            <TableCell>{item.url}</TableCell>
                                            <TableCell>
                                                <div className="d-flex">
                                                    <div>
                                                        {' '}
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() =>
                                                                handleEdit(item)
                                                            }
                                                        >
                                                            Edit
                                                        </Button>
                                                    </div>

                                                    <div className="ml-2">
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => {
                                                                if (
                                                                    window?.confirm(
                                                                        'Are you sure you want to delete this?'
                                                                    )
                                                                ) {
                                                                    DeleteBannerData(
                                                                        token,
                                                                        {
                                                                            id: item?.id
                                                                        }
                                                                    )
                                                                        .then(
                                                                            () => {
                                                                                setItems(
                                                                                    (
                                                                                        prevItems
                                                                                    ) =>
                                                                                        prevItems.filter(
                                                                                            (
                                                                                                i
                                                                                            ) =>
                                                                                                i.id !==
                                                                                                item.id
                                                                                        )
                                                                                );
                                                                            }
                                                                        )
                                                                        .catch(
                                                                            () => {
                                                                                alert(
                                                                                    'Something went wrong.'
                                                                                );
                                                                            }
                                                                        );
                                                                }
                                                            }}
                                                            className="ml-3"
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>
        </Dialog>
    );
};
export default connect(null, {
    CreateBannerData,
    GetBannerData,
    UpdateBannerData,
    DeleteBannerData,
    CommonDispatcher
})(AddBannerModal);
