import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Routes } from '../../constants';
import {
    CommonDispatcher,
    GetAdminProfile,
    GetCategories,
    GetSectors
} from '../../redux/Common/action';
import { SET_FOUNDED_YEAR_LIST } from '../../redux/Common/actionTypes';
import { RootState } from '../../redux/store';
import ClientConfig from '../ClientConfig';
import AddSupportUserEmail from '../ClientConfig/AddSupportUserEmail';
import ClientConfigDetails from '../ClientConfig/ClientConfigDetails';
import ActivateSubscriptionModal from '../Common/ActivateSubscriptionModal';
import AlertModalComponent from '../Common/AlertModal';
import ChangeOwnershipModal from '../Common/ChangeOwnershipModal';
import ChangePasswordModal from '../Common/ChangePasswordModal/ChangePasswordModal';
import ExtendClosingDateModal from '../Common/ExtendClosingDateModal';
import CommonSnackbar from '../CommonSnackbar';
import Deals from '../Deals';
import AddEdit from '../Deals/AddEdit';
import AddTeamToDeal from '../Deals/AddTeam';
import CapTable from '../Deals/CapTable';
import DealsDetails from '../Deals/DealsDetails';
import DetailView from '../DetailView';
import AddEditEvents from '../Edit/Events';
import EditStories from '../Edit/Stories';
import Events from '../Events';
import EventDetailPage from '../Events/EventDetails';
import Feedback from '../Feedback';
import Firms from '../Firms';
import FundsDeals from '../FundDeals';
import FundsAddEdit from '../FundDeals/AddEdit';
import FundAddTeamToDeal from '../FundDeals/AddTeam';
import FundRaiseDeals from '../FundDeals/FundraiseDeal';
import Header from '../Header';
import MetaDataForm from '../MetaData';
import CategoryForm from '../Category';
import AddMetaDataModal from '../MetaData/AddMetaDataModal';
import Referral from '../Referral';
import Stories from '../Stories';
import Subscriptions from '../Subscriptions';
import ExtendSubscriptionModal from '../Subscriptions/ExtendSubscriptionModal';
import Users from '../Users';
import AddMarketingEmailModal from '../Users/Common/AddMarketingEmailModal';
import AffiliatedModal from '../Users/Common/AffiliatedModal';
import SessionsModal from '../Users/Common/SessionsModal';
import EditStrategy from '../Users/EditStrategy';
import EditUser from '../Users/EditUser';
import ViewUsers from '../Users/ViewUser';
import ProductsServices from '../ProductsServices';
import EditProductsServices from '../Edit/ProductsServices';
import UpdateUserEmail from '../Users/Common/UpdateUserEmail';
import AddNote from '../Users/AddNote';
import AddBannerModal from '../Users/Common/AddbannerModal';

const Page = ({
    CommonDispatcher,
    GetCategories,
    GetSectors,
    GetAdminProfile
}) => {
    const snackbar =
        useSelector((store: RootState) => store?.commonAppData.snackbar) || {};
    const modal: any =
        useSelector((store: RootState) => store?.modal?.changeOwnershipModal) ||
        {};
    const extendSubscriptionModal: any =
        useSelector(
            (store: RootState) => store?.modal?.extendSubscriptionModal
        ) || {};
    const activateSubscriptionModal: any =
        useSelector(
            (store: RootState) => store?.modal?.activateSubscriptionModal
        ) || {};
    const changePasswordModal: any =
        useSelector((store: RootState) => store?.modal?.changePasswordModal) ||
        {};
    const [isLoading, setIsLoading] = useState(false);
    const alertModal: any =
        useSelector((store: RootState) => store?.modal?.alertModal) || {};
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const extendClosingDateModal: any =
        useSelector(
            (store: RootState) => store?.modal?.extendClosingDateModal
        ) || {};

    useEffect(() => {
        CommonDispatcher(SET_FOUNDED_YEAR_LIST, {});
        setIsLoading(true);
        let arr = [];
        arr.push(GetAdminProfile(token));
        arr.push(GetCategories(token));
        arr.push(GetSectors(token));

        axios.all(arr).then(() => {
            setIsLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const sessionsModal = useSelector(
        (store: RootState) => store?.modal?.sessionsModal
    );
    const affiliatedModal = useSelector(
        (store: RootState) => store?.modal?.affiliatedModal
    );
    const addBannerModal = useSelector(
        (store: RootState) => store?.modal?.addBannerModal
    );
    const addSuportUserModal = useSelector(
        (store: RootState) => store?.modal?.addSuportUserModal
    );
    const addMarketingEmailModal = useSelector(
        (store: RootState) => store?.modal?.addMarketingEmailModal
    );
    const addMetaDataModals = useSelector(
        (store: RootState) => store?.modal?.addMetaDataModal
    );
    const changeUserEmailModal = useSelector(
        (store: RootState) => store?.modal?.changeUserEmailModal
    );
    const addAdressbookNoteModal = useSelector(
        (store: RootState) => store?.modal?.addAdressbookNoteModal
    );

    return (
        <>
            <Header />
            <div className="d-flex mainContent">
                <div className="w-100">
                    {sessionsModal?.isOpen ? <SessionsModal /> : null}
                    {addAdressbookNoteModal?.isOpen ? <AddNote /> : null}

                    {affiliatedModal?.isOpen ? <AffiliatedModal /> : null}
                    {addBannerModal?.isOpen ? <AddBannerModal /> : null}

                    <Route
                        path={Routes.clientConfig.clientConfigDetails}
                        exact
                        render={(props) => <AddSupportUserEmail {...props} />}
                    />
                    {/* {addSuportUserModal?.isOpen ? (
                        <AddSupportUserEmail />
                    ) : null} */}

                    {addMarketingEmailModal?.isOpen ? (
                        <AddMarketingEmailModal />
                    ) : null}

                    {addMetaDataModals?.isOpen ? <AddMetaDataModal /> : null}
                    {changeUserEmailModal?.isOpen ? <UpdateUserEmail /> : null}

                    {extendClosingDateModal.isOpen ? (
                        <ExtendClosingDateModal />
                    ) : null}
                    {alertModal?.isOpen ? <AlertModalComponent /> : false}
                    {snackbar?.isShowing ? <CommonSnackbar /> : false}
                    {modal?.isOpen ? <ChangeOwnershipModal /> : null}
                    {extendSubscriptionModal?.isOpen ? (
                        <ExtendSubscriptionModal />
                    ) : null}
                    {activateSubscriptionModal?.isOpen ? (
                        <ActivateSubscriptionModal />
                    ) : null}
                    {changePasswordModal?.isOpen ? (
                        <ChangePasswordModal />
                    ) : null}
                    {isLoading ? (
                        <div className="mainLoader">
                            <div className="loader" />
                        </div>
                    ) : (
                        <Switch>
                            <Route
                                path={Routes.home}
                                exact
                                render={(props) => {
                                    return '';
                                }}
                            />

                            <Route
                                path={Routes.users.editStrategy}
                                exact
                                render={(props) => <EditStrategy {...props} />}
                            />
                            <Route
                                path={Routes.users.editUser}
                                exact
                                render={(props) => <EditUser {...props} />}
                            />
                            <Route
                                path={Routes.users.viewUser}
                                exact
                                render={(props) => <ViewUsers {...props} />}
                            />
                            <Route
                                path={Routes.deals.addTeam}
                                exact
                                render={(props) => <AddTeamToDeal {...props} />}
                            />
                            <Route
                                path={Routes.fund_deals.addTeam}
                                exact
                                render={(props) => (
                                    <FundAddTeamToDeal {...props} />
                                )}
                            />

                            <Route
                                path={Routes.events.editEvents}
                                exact
                                render={(props) => <AddEditEvents {...props} />}
                            />
                            <Route
                                path={Routes.events.eventsDetails}
                                exact
                                render={(props) => (
                                    <EventDetailPage {...props} />
                                )}
                            />
                            <Route
                                path={Routes.events.events}
                                exact
                                render={(props) => <Events {...props} />}
                            />
                            <Route
                                path={Routes.editStories}
                                exact
                                render={(props) => <EditStories {...props} />}
                            />
                            <Route
                                path={Routes.editProductsServices}
                                exact
                                render={(props) => (
                                    <EditProductsServices {...props} />
                                )}
                            />
                            <Route
                                path={Routes.referral.email}
                                render={(props) => <Referral {...props} />}
                            />
                            <Route
                                path={Routes.subscriptions}
                                exact
                                render={(props) => <Subscriptions {...props} />}
                            />
                            <Route
                                path={Routes.clientConfig.index}
                                exact
                                render={(props) => <ClientConfig {...props} />}
                            />
                            <Route
                                path={Routes.clientConfig.clientConfigDetails}
                                exact
                                render={(props) => (
                                    <ClientConfigDetails {...props} />
                                )}
                            />

                            <Route
                                path={Routes.feedback}
                                exact
                                render={(props) => <Feedback {...props} />}
                            />
                            <Route
                                path={Routes.stories}
                                exact
                                render={(props) => <Stories {...props} />}
                            />
                            <Route
                                path={Routes.storiesDetails}
                                render={(props) => <DetailView {...props} />}
                            />
                            <Route
                                path={Routes.users.users}
                                render={(props) => <Users {...props} />}
                            />
                            <Route
                                path={Routes.deals.capTable}
                                render={(props) => <CapTable {...props} />}
                            />
                            <Route
                                path={Routes.deals.dealDetails}
                                render={(props) => <DealsDetails {...props} />}
                            />
                            <Route
                                path={Routes.deals.editDeal}
                                render={(props) => <AddEdit {...props} />}
                            />
                            <Route
                                path={Routes.deals.deals}
                                render={(props) => <Deals {...props} />}
                            />

                            <Route
                                path={Routes.fund_deals.dealDetails}
                                render={(props) => (
                                    <FundRaiseDeals {...props} />
                                )}
                            />
                            <Route
                                path={Routes.fund_deals.editDeal}
                                render={(props) => (
                                    <FundsAddEdit
                                        {...props}
                                        isFundFundraise={true}
                                    />
                                )}
                            />
                            <Route
                                path={Routes.fund_deals.deals}
                                render={(props) => (
                                    <FundsDeals
                                        {...props}
                                        isFundFundraise={true}
                                    />
                                )}
                            />
                            <Route
                                path={Routes.firms.firms}
                                render={(props) => <Firms {...props} />}
                            />
                            <Route
                                path={Routes.productsServices.index}
                                render={(props) => (
                                    <ProductsServices {...props} />
                                )}
                            />
                            <Route
                                path={Routes.metadata.metadata}
                                render={(props) => <MetaDataForm {...props} />}
                            />
                            <Route
                                path={Routes.category}
                                render={(props) => <CategoryForm {...props} />}
                            />
                            {/* <Route
                                path={Routes.changePassword}
                                render={(props) => (
                                    <ChangePassword {...props} />
                                )}
                            /> */}
                        </Switch>
                    )}
                </div>
            </div>
        </>
    );
};
export default connect(null, {
    CommonDispatcher,
    GetCategories,
    GetSectors,
    GetAdminProfile
})(Page);
