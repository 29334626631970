import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
    Grid,
    Button,
    Container,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    TablePagination
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { connect } from 'react-redux';

import { useState } from 'react';
import { useCookies } from 'react-cookie';
import {
    AddOrUpdateCategory,
    GetValuesByCategory
} from '../../redux/Category/actions';
import RenderTextField from '../InputComponents/TextField/RenderTextField';
import { SHOW_SNACKBAR } from '../../redux/Common/actionTypes';
import { CommonDispatcher } from '../../redux/Common/action';

const metadataCategories = [
    'regions',
    'country',
    'investmentType',
    'businessModel',
    'startupType',
    'sectors',
    'fundingStage',
    'sdgSectors',
    'firmClassify',
    'STORIES',
    'EVENT',
    'SERVICE',
    'JOB_SERVICE'
];

const CategoryForm = ({
    AddOrUpdateCategory,
    GetValuesByCategory,
    CommonDispatcher
}) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const [isLoading, setIsLoading] = useState(false);
    const { control, reset, getValues } = useForm({
        defaultValues: {
            category: [
                {
                    label: '',
                    category: '',
                    rank: null,
                    id: null,
                    url_identifier: ''
                }
            ]
        }
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [fetchedData, setFetchedData] = useState<any>([]); // Data for current category

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'category'
    });

    const [expanded, setExpanded] = useState<string | false>(false); // Track which accordion is open

    const handleChangeAccordion =
        (panel: string) => (event: any, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
            if (isExpanded && metadataCategories.includes(panel)) {
                fetchCategoryData(panel);
            }
        };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when the number of rows per page is changed
    };

    const fetchCategoryData = async (category: string) => {
        setIsLoading(true);
        try {
            const response = await GetValuesByCategory(token, { category });
            setFetchedData(response?.data?.data || []);
            reset({ category: response?.data?.data || [] });
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching category data:', error);
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Failed to fetch data for this category.',
                hideAlert: true,
                error: true
            });
            setIsLoading(false);
        }
    };

    const onSubmit = (e, index = null, item = null) => {
        e.preventDefault();
        let data = getValues();
        let categoryData =
            item.id !== null
                ? { ...fetchedData[index], ...data.category[index] }
                : { ...data.category[index], category: item.category };
        if (categoryData.rank === '') {
            categoryData.rank = null;
        }

        AddOrUpdateCategory(token, categoryData)
            .then((response) => {
                if (response.type === 'success') {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: `Category ${
                            item.id === null ? 'added' : 'updated'
                        } successfully.`,
                        hideAlert: true
                    });
                } else {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: `Failed to ${
                            item.id === null ? 'add' : 'update'
                        } category.`,
                        hideAlert: true,
                        error: true
                    });
                }
            })
            .catch(() => {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: `Failed to ${
                        item.id === null ? 'add' : 'update'
                    } category.`,
                    hideAlert: true,
                    error: true
                });
            });
    };

    const handleAddNewRow = (category) => {
        const newRow = {
            label: '',
            category,
            rank: null,
            id: null,
            url_identifier: ''
        };

        append(newRow);

        setFetchedData((prevData) => [...prevData, newRow]);
    };
    const paginatedData = fetchedData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <Container maxWidth="md">
            <Grid
                container
                spacing={3}
                className="mt-4 d-flex flex-direction-column"
            >
                <div>
                    {metadataCategories.map((category) => (
                        <Accordion
                            key={category}
                            expanded={expanded === category}
                            onChange={handleChangeAccordion(category)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>
                                    {category.charAt(0).toUpperCase() +
                                        category.slice(1)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {isLoading ? (
                                    <Typography>Loading...</Typography>
                                ) : (
                                    <div>
                                        {fetchedData.length > 0 ? (
                                            <>
                                                {paginatedData.map(
                                                    (item, index) => (
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            key={item.id}
                                                        >
                                                            <Grid item xs={3}>
                                                                <Controller
                                                                    name={`category[${index}].label`}
                                                                    render={({
                                                                        onChange,
                                                                        value
                                                                    }) => (
                                                                        <RenderTextField
                                                                            id={`category[${index}].label`}
                                                                            label={`Label *`}
                                                                            value={
                                                                                value
                                                                            }
                                                                            onChange={
                                                                                onChange
                                                                            }
                                                                            placeholder="Search or Add name"
                                                                        />
                                                                    )}
                                                                    control={
                                                                        control
                                                                    }
                                                                    defaultValue={
                                                                        item.label ||
                                                                        ''
                                                                    }
                                                                    rules={{
                                                                        required:
                                                                            false
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Controller
                                                                    name={`category[${index}].rank`}
                                                                    render={({
                                                                        onChange,
                                                                        value
                                                                    }) => (
                                                                        <RenderTextField
                                                                            inputContainerClassName="w-100"
                                                                            type="text"
                                                                            id={`category[${index}].rank`}
                                                                            label={`Rank *`}
                                                                            placeholder="Search or Add rank"
                                                                            value={
                                                                                value
                                                                            }
                                                                            onChange={
                                                                                onChange
                                                                            }
                                                                            autoComplete="off"
                                                                        />
                                                                    )}
                                                                    control={
                                                                        control
                                                                    }
                                                                    defaultValue={
                                                                        item.rank ||
                                                                        ''
                                                                    }
                                                                    rules={{
                                                                        required:
                                                                            false
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Controller
                                                                    name={`category[${index}].url_identifier`}
                                                                    render={({
                                                                        onChange,
                                                                        value
                                                                    }) => (
                                                                        <RenderTextField
                                                                            id={`category[${index}].url_identifier`}
                                                                            label={`Url Identifier *`}
                                                                            value={
                                                                                value
                                                                            }
                                                                            onChange={
                                                                                onChange
                                                                            }
                                                                            placeholder="Search or Url identifier"
                                                                        />
                                                                    )}
                                                                    control={
                                                                        control
                                                                    }
                                                                    defaultValue={
                                                                        item.url_identifier ||
                                                                        ''
                                                                    }
                                                                    rules={{
                                                                        required:
                                                                            false
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={2}
                                                                style={{
                                                                    alignContent:
                                                                        'end'
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        onSubmit(
                                                                            e,
                                                                            index,
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    {item.id
                                                                        ? 'Update'
                                                                        : 'Add'}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                )}
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Button
                                                        type="button"
                                                        onClick={() =>
                                                            handleAddNewRow(
                                                                category
                                                            )
                                                        }
                                                        startIcon={
                                                            <AddCircleIcon />
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Add New
                                                    </Button>
                                                    <TablePagination
                                                        rowsPerPageOptions={[
                                                            10, 25, 50
                                                        ]}
                                                        component="div"
                                                        count={
                                                            fetchedData.length
                                                        }
                                                        rowsPerPage={
                                                            rowsPerPage
                                                        }
                                                        page={page}
                                                        onPageChange={
                                                            handleChangePage
                                                        }
                                                        onRowsPerPageChange={
                                                            handleChangeRowsPerPage
                                                        }
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <Typography>
                                                No data available
                                            </Typography>
                                        )}
                                    </div>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    AddOrUpdateCategory,
    GetValuesByCategory,
    CommonDispatcher
})(CategoryForm);
