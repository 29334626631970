import {
    Avatar,
    Chip,
    Table,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import React, { Suspense, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FirmsTab, ROWS_PER_PAGE, Routes } from '../../../constants';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import {
    DeleteFirm,
    GetFirmsList,
    UpdateFirm
} from '../../../redux/Firms/actions';
import {
    ADD_ADDRESS_BOOK_NOTE_MODAL,
    IMPORT_FIRM_MODAL,
    OPEN_ALERT_MODAL,
    SHOW_USER_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import { getQuery } from '../../../utils/common';
import CommonModalWrapper from '../../Common/CommonModal';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import TableHeaderFilter from '../../Common/TableHeaderFilter';
import { OpenAlert } from '../../Users/Common';
import { CommonPaginationAction } from '../../Users/Common/CommonPaginationAction';
import AddEditFirm from '../AddEditFirm';
import style from '../AllFirms/Style.module.scss';
import WebsiteURLWrapper from '../Common/WebsiteURLWrapper';
import TagsPopup from '../../Common/TagsPopup';
import { GetAddressBookAllTags } from '../../../redux/Firms/actions';
import { NoteAdd, NoteAddOutlined } from '@material-ui/icons';
import { FirmsTypeList } from '../../../utils/constants';

const AddNewFirm = React.lazy(() => import('../AddNewFirm'));

const options = [
    { title: 'Edit', value: 'edit' },
    { title: 'Delete', value: 'delete' },
    { title: 'Show to user', value: 'show_user' },
    { title: 'Affiliation', value: 'is_affiliated' }
];

const ActiveComponent = ({
    sectionBaseUrl,
    GetFirmsList,
    DeleteFirm,
    loading,
    isMainFilterApplied,
    getAllTabsData,
    UpdateFirm,
    OpenModal,
    GetAddressBookAllTags,
    CommonDispatcher,
    getSelectedTabData,
    handleTabLoading,
    isTabLoading
}: any) => {
    const classes = CommonTableStyle();
    const [isFilterApplied, setIsFilterApplied] = useState(isMainFilterApplied);
    const [isLoading, setIsLoading] = useState(false);
    const [openEditFirmModel, setOpenEditFirmModel] = useState(false);
    const [selectedFirmId, setSelectedFirmId] = useState(null);
    const [isAffiliated, setIsisAffiliated] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>({});
    const tableHeader = useSelector(
        (store: RootState) => store?.firms.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.firms.allFirmsList
    );
    const history = useHistory();
    const query = getQuery(history);
    const type = query.get('type');
    const tags = query.get('tags_ids');
    const keyword = query.get('keyword');
    const [page, setPage] = useState(0);
    const count = listingsData?.count;
    const total_pages = listingsData?.total_pages;
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter');
    let decodedFilters;
    if (filterParam) {
        decodedFilters = JSON.parse(decodeURIComponent(filterParam));
    }
    useEffect(() => {
        GetAddressBookAllTags(
            {
                page_number: 1
            },
            false,
            token
        ).then((x) => {
            setIsLoading(false);
        });
    }, []);
    useEffect(() => {
        if (
            !isFilterApplied &&
            (keyword || type || tags || typeof page !== 'undefined')
        ) {
            getData({
                keyword,
                type,
                page: page + 1,
                filter: { tags_ids: tags ? tags?.split(',') : null },
                tab: FirmsTab.TYPE_ACTIVE
            });
        }
        // eslint-disable-next-line
    }, [keyword, type, page, tags]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const getData = (data) => {
        data.size = ROWS_PER_PAGE;
        setIsLoading(true);
        if (filterParam) {
            data.filter = decodedFilters;
        }

        GetFirmsList(token, data).then(() => {
            setIsLoading(false);
            handleTabLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setIsFilterApplied(false);
    };
    const toggleAffiliated = (selectedUser, value) => {
        UpdateFirm(token, {
            firm_id: selectedUser?.firm_id,
            [value]: !selectedUser?.is_affiliated
        }).then(() => {
            getAllTabsData({
                page
            });
        });
        setIsisAffiliated(!isAffiliated);
    };

    const CommonComponent = () => (
        <CommonPaginationAction
            count={count}
            page={page}
            setPage={setPage}
            total_pages={total_pages}
        />
    );

    const handleChange = (value, selectedUser) => {
        switch (value) {
            case 'edit':
                setOpenEditFirmModel(true);
                setSelectedFirmId(selectedUser?.firm_id);
                break;
            case 'delete':
                if (
                    window?.confirm(
                        'Are you sure you want to delete this firm?'
                    )
                ) {
                    DeleteFirm(token, selectedUser?.firm_id)
                        .then(() => getSelectedTabData(page))
                        .catch(() => {
                            alert('Something went wrong.');
                        });
                }
                break;
            case 'show_user':
                OpenModal(SHOW_USER_MODAL, {
                    isOpen: true,
                    title: `Show to user`,
                    data: {
                        selectedUser,
                        current_page: page + 1,
                        tab: FirmsTab.TYPE_ACTIVE,
                        size: ROWS_PER_PAGE
                    },
                    config: {
                        modalSize: 'xs'
                    }
                });
                break;
            case 'is_affiliated':
                if (
                    window?.confirm(
                        `Are you sure you want to ${
                            !selectedUser?.is_affiliated ? 'activate' : 'remove'
                        } the affiliation of this firm?`
                    )
                ) {
                    toggleAffiliated(selectedUser, value);
                }
                break;
        }
    };

    const renderMenu = ({
        firm_id,
        label,
        is_enabled,
        is_lp_enabled,
        is_affiliated,
        is_family_enabled,
        is_public_enabled,
        is_public_website_enabled,
        is_partner_public_enabled,
        is_partner_public_url_enabled
    }) => {
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) =>
                        handleClick(e, {
                            firm_id,
                            label,
                            is_enabled,
                            is_lp_enabled,
                            is_affiliated,
                            is_family_enabled,
                            is_public_enabled,
                            is_public_website_enabled,
                            is_partner_public_enabled,
                            is_partner_public_url_enabled
                        })
                    }
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    elevation={0}
                    PaperProps={{
                        style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            border: '1px solid rgb(0 106 194 / 20%)'
                        }
                    }}
                >
                    {options.map((option, i) => (
                        <MenuItem
                            key={option?.value}
                            onClick={() => {
                                handleChange(option?.value, selectedUser);
                                setAnchorEl(null);
                            }}
                        >
                            {option?.title === 'Affiliated'
                                ? selectedUser?.is_affiliated == false
                                    ? `Add ${option?.title}`
                                    : `Remove ${option?.title}`
                                : option?.title}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };

    const handleCloseModal = () => {
        setOpenEditFirmModel(false);
        setSelectedFirmId(null);
    };

    const handleToggle = (is_enabled, firm_id) => {
        OpenAlert({
            title: `${is_enabled ? 'Activate' : 'Deactivate'}`,
            description: `Are you sure you want to ${
                is_enabled ? 'Activate' : 'Deactivate'
            }?`,
            submitTitle: `${is_enabled ? 'Activate' : 'Deactivate'}`,
            token,
            payload: {
                firm_id,
                is_enabled
            },
            OpenModal,
            UpdateUserProfile: UpdateFirm,
            getData,
            keyword,
            type,
            page,
            tab: FirmsTab.TYPE_ACTIVE
        });
    };

    const toggleImportFirmsModal = () => {
        OpenModal(IMPORT_FIRM_MODAL, {
            isOpen: true,
            title: 'Import Firms',
            data: {
                disableBackdropClick: true
            },
            submitTitle: 'Submit',
            cancelTitle: 'Ok',
            isDelete: true,
            maxWidth: 'md'
        });
    };
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleAddNoteModal = (type, title, address_book) => {
        CommonDispatcher(type, {
            isOpen: true,
            title,
            data: {
                disableBackdropClick: true,
                data: { address_book }
            },
            submitTitle: 'Save',
            cancelTitle: 'Cancel'
        });
    };
    const renderAddNote = (d) => {
        return (
            // <div className={style.addNote}>
            <IconButton
                aria-label="edit"
                onClick={(e) => {
                    e.stopPropagation();
                    handleAddNoteModal(ADD_ADDRESS_BOOK_NOTE_MODAL, `Note`, d);
                }}
            >
                <NoteAddOutlined fontSize="medium" className="colorGray" />
            </IconButton>
            // </div>
        );
    };

    const renderNote = (d) => {
        return (
            // <div className={style.noteAdded}>
            <IconButton
                aria-label="edit"
                onClick={(e) => {
                    e.stopPropagation();
                    handleAddNoteModal(ADD_ADDRESS_BOOK_NOTE_MODAL, `Note`, d);
                }}
            >
                <NoteAdd fontSize="medium" className="colorPurple" />
            </IconButton>
            // </div>
        );
    };

    return (
        <Grid container spacing={0} className="h-100">
            {openEditFirmModel ? (
                <CommonModalWrapper
                    modal={{
                        isOpen: openEditFirmModel,
                        config: { modalSize: 'sm' },
                        title: 'Edit Firm'
                    }}
                    onClose={handleCloseModal}
                >
                    <AddEditFirm
                        firm_id={selectedFirmId}
                        getAllData={() =>
                            getData({
                                keyword,
                                type,
                                page: page + 1,
                                tab: FirmsTab.TYPE_ACTIVE
                            })
                        }
                        handleCloseModal={handleCloseModal}
                    />
                </CommonModalWrapper>
            ) : null}
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <div className="d-flex align-items-center flex-wrap">
                        <div className="flex-grow-1">
                            <TableHeaderFilter
                                filterDataArray={FirmsTypeList}
                            />
                        </div>
                        <div className="mr-5">
                            <Suspense fallback={<div>Loading...</div>}>
                                <AddNewFirm
                                    toggleImportFirmsModal={
                                        toggleImportFirmsModal
                                    }
                                    getAllData={() =>
                                        getData({
                                            keyword,
                                            type,
                                            page: page + 1
                                        })
                                    }
                                    type={type}
                                    tab={FirmsTab.TYPE_ACTIVE}
                                    keyword={keyword}
                                />{' '}
                            </Suspense>
                        </div>
                    </div>
                    {loading || isLoading || isTabLoading ? (
                        <div className="mainLoader">
                            <div className="loader" />
                        </div>
                    ) : (
                        <>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeader?.map((column) => (
                                                <TableCell key={column.id}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listingsData?.posts &&
                                        listingsData?.posts.length ? (
                                            listingsData?.posts?.map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row?.firm_id}
                                                        style={{
                                                            padding: 0,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <TableCell
                                                            size={'small'}
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                        >
                                                            {renderMenu(row)}
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <TagsPopup
                                                                d={row}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                minWidth:
                                                                    '100px'
                                                            }}
                                                            size={'small'}
                                                        >
                                                            {moment(
                                                                row?.joining_date
                                                            ).format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            <Chip
                                                                size="small"
                                                                label={
                                                                    row?.is_active
                                                                        ? 'Active'
                                                                        : 'InActive'
                                                                }
                                                                clickable
                                                                color={
                                                                    row?.is_active
                                                                        ? 'primary'
                                                                        : 'secondary'
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            <Avatar
                                                                alt={`${
                                                                    row?.firm_log_full_url ||
                                                                    row?.file_url
                                                                }-logo`}
                                                                className={
                                                                    style.avatar
                                                                }
                                                                src={
                                                                    row?.firm_log_full_url ||
                                                                    row?.file_url
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex mr-2 ">
                                                                    {row?.label ||
                                                                        '-'}
                                                                </div>
                                                                {!isTabletScreen ? (
                                                                    <>
                                                                        {row?.note
                                                                            ? renderNote(
                                                                                  row
                                                                              )
                                                                            : renderAddNote(
                                                                                  row
                                                                              )}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            <WebsiteURLWrapper
                                                                website={
                                                                    row?.website
                                                                }
                                                            >
                                                                {row?.website ||
                                                                    '-'}
                                                            </WebsiteURLWrapper>
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.type ? (
                                                                <Chip
                                                                    size="small"
                                                                    label={
                                                                        row?.type
                                                                    }
                                                                    color={
                                                                        row?.type ===
                                                                        'INVESTOR'
                                                                            ? 'primary'
                                                                            : 'secondary'
                                                                    }
                                                                    clickable
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.users &&
                                                            row?.users?.length
                                                                ? row?.users.map(
                                                                      (d) => {
                                                                          return (
                                                                              <a
                                                                                  href={`${Routes.users.viewUser.replace(
                                                                                      ':user_id',
                                                                                      d?.user_id
                                                                                  )}`}
                                                                                  target="_blank"
                                                                                  rel="noopener noreferrer"
                                                                                  className="text-decoration-none colorPrimary"
                                                                                  key={
                                                                                      d?.user_id
                                                                                  }
                                                                              >
                                                                                  <Chip
                                                                                      size="small"
                                                                                      className="mr-2 my-2"
                                                                                      label={
                                                                                          d?.full_name
                                                                                      }
                                                                                      clickable
                                                                                  />
                                                                              </a>
                                                                          );
                                                                      }
                                                                  )
                                                                : '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        tableHeader.length + 1
                                                    }
                                                    className="dataNotAvailable"
                                                >
                                                    Data not available
                                                </td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className={classes.footer}
                                hidden={
                                    listingsData?.posts &&
                                    !listingsData?.posts.length
                                }
                                rowsPerPageOptions={[]}
                                component="div"
                                count={count}
                                rowsPerPage={listingsData?.page_size}
                                page={page}
                                ActionsComponent={CommonComponent}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default connect(null, {
    GetFirmsList,
    OpenModal,
    UpdateFirm,
    DeleteFirm,
    GetAddressBookAllTags,
    CommonDispatcher
})(ActiveComponent);
