import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
    Grid,
    Button,
    IconButton,
    FormControlLabel,
    Container,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    CircularProgress,
    Avatar,
    Switch,
    Checkbox
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import imageCompression from 'browser-image-compression';

import DeleteIcon from '@material-ui/icons/Delete';
import { connect, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
    createMetadata,
    fetchMetadataList,
    DeleteMetaData,
    updateMetadata,
    UploadMetaData
} from '../../redux/MetaData/actions';
import { RootState } from '../../redux/store';
import { CommonDispatcher } from '../../redux/Common/action';
import style from './Style.module.scss';
import {
    ADD_META_DATA_MODAL,
    OPEN_ALERT_MODAL
} from '../../redux/ModalReducer/actionTypes';
import {
    GetSingleClient,
    UpdateClient
} from '../../redux/ClientConfig/actions';
import RenderTextField from '../InputComponents/TextField/RenderTextField';
import { SHOW_SNACKBAR } from '../../redux/Common/actionTypes';
import RenderSelectField from '../InputComponents/SelectField/RenderSelectField';

const MetaDataForm = ({
    fetchMetadataList,
    createMetadata,
    CommonDispatcher,
    GetSingleClient,
    UpdateClient,
    updateMetadata,
    DeleteMetaData,
    UploadMetaData
}) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const metadataList: any = useSelector(
        (state: RootState) => state?.metaData?.metadataList
    );
    const [isEnabled, setIsEnabled] = useState({});
    const profileDetails: any = useSelector(
        (state: RootState) => state?.commonAppData?.profile
    );
    // const [isImageUploadLoading, setImageLoading] = useState(false);
    const [isMetaDataImageUploadLoading, setIsMetaDataImageUploadLoading] =
        useState(false);
    const [selectedImages, setSelectedImages] = useState({});
    const [checked, setChecked] = useState({});
    const [dataArray, setDataArray] = useState([]);
    const [selectedParentMetadata, setSelectedParentMetadata] = useState({});
    const [imageLoading, setImageLoading] = useState({
        METADATA_FAVICON: false,
        METADATA_LOGO: false,
        METADATA_MOBILE_LOGO: false
    });

    const [uploadedImages, setUploadedImages] = useState({
        METADATA_FAVICON: '',
        METADATA_LOGO: '',
        METADATA_MOBILE_LOGO: ''
    });
    const uploadImage = (e, imageType) => {
        const files = e.target.files;
        if (files && files.length) {
            setImageLoading((prev) => ({ ...prev, [imageType]: true }));
            let formData = new FormData();
            formData.append('type', imageType);
            formData.append('files', files[0], files[0]?.name);

            return UploadMetaData(token, formData).then((r) => {
                if (r?.type === 'failed') {
                    setImageLoading((prev) => ({
                        ...prev,
                        [imageType]: false
                    }));
                    // Show error message
                } else {
                    setUploadedImages((prev) => ({
                        ...prev,
                        [imageType]: r?.response?.file_url
                    }));
                    setImageLoading((prev) => ({
                        ...prev,
                        [imageType]: false
                    }));
                }
            });
        }
    };
    const { control, handleSubmit, setValue, reset, getValues } = useForm({
        defaultValues: {
            metadata: [
                {
                    label: '',
                    identifier: '',
                    title: '',
                    description: '',
                    seo_keywords: '',
                    headline: '',
                    help_chat_value: '',
                    imageUpload: '',
                    logoUpload: '',
                    mobileLogoUpload: '',
                    is_external: true,
                    client_url: '',
                    meta_id: ''
                }
            ],
            general_title: '',
            newuser_welcome_url: '',
            help_center_url: '',
            help_chat_value: '',
            google_analytics_id: '',
            ecosystem_partner_text: ''
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'metadata'
    });

    useEffect(() => {
        fetchMetadataList(token);
    }, [token]);

    useEffect(() => {
        let formattedMetadata = [];
        let metadata = [];
        if (metadataList && metadataList?.metadata?.length) {
            const enabledStates = {};
            metadataList.metadata.forEach((meta) => {
                enabledStates[meta.meta_id] = meta.is_enabled;
            });
            formattedMetadata = metadataList.metadata.map((meta) => ({
                ...meta,
                // Remove duplicate slashes from the identifier
                identifier: meta.identifier.startsWith('/')
                    ? meta.identifier.substring(1)
                    : ''
            }));
            setIsEnabled(enabledStates);
            const children = {};
            metadataList.metadata.forEach((meta) => {
                children[meta.meta_id] = meta.is_submenu;
            });
            setChecked(children);
            metadata = metadataList.metadata.map((meta) => ({
                title: meta?.label,
                value: meta?.meta_id
            }));
        }
        setDataArray(metadata);
        reset({
            general_title: profileDetails?.client?.general_title,
            newuser_welcome_url: profileDetails?.client?.newuser_welcome_url,
            help_center_url: profileDetails?.client?.help_center_url,
            help_chat_value: profileDetails?.client?.help_chat_value,

            google_analytics_id: profileDetails?.client?.google_analytics_id,
            ecosystem_partner_text:
                profileDetails?.client?.ecosystem_partner_text,

            metadata: formattedMetadata
        });
        setUploadedImages({
            METADATA_FAVICON: profileDetails?.client?.favicon_logo,
            METADATA_LOGO: profileDetails?.client?.logo,
            METADATA_MOBILE_LOGO: profileDetails?.client?.mobile_logo
        });
    }, [metadataList, profileDetails, reset]);

    const handleImageChange = (event, index) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            setIsMetaDataImageUploadLoading(true);
            // Create an image object to load the selected file
            const img: any = new Image();
            img.onload = () => {
                // Check if the image meets the size requirements
                if (img.naturalWidth <= 1600 && img.naturalHeight <= 1600) {
                    // If the image is valid, compress it and update state
                    imageCompression(file, { maxSizeMB: 1 }).then(
                        (compressedFile) => {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                setSelectedImages((prev) => ({
                                    ...prev,
                                    [index]: e.target.result
                                }));
                            };
                            reader.readAsDataURL(compressedFile);
                            setValue(
                                `metadata[${index}].imageUpload`,
                                compressedFile
                            );
                            setIsMetaDataImageUploadLoading(false);
                        }
                    );
                } else {
                    // If the image is not valid, show an error message
                    setIsMetaDataImageUploadLoading(false);
                    alert('Image size must be 1600x1600 pixels.');
                }
            };
            img.onerror = () => {
                setIsMetaDataImageUploadLoading(false);
                alert('Failed to load image. Please try again.');
            };
            // Read the file as a data URL to trigger the img.onload event
            const reader = new FileReader();
            reader.onload = (e) => (img.src = e.target.result);
            reader.readAsDataURL(file);
        }
    };
    const onSubmit = (e, index) => {
        e && e.preventDefault();
        let data = getValues();
        let API = null;
        const identifier = data.metadata[index].identifier.startsWith('/')
            ? data.metadata[index].identifier
            : `/${data.metadata[index].identifier}`;
        const payload = {
            ...data.metadata[index],
            client_url: data.metadata[index]?.client_url?.length
                ? data.metadata[index]?.client_url
                : null,
            is_external: 1,
            identifier,
            is_submenu: checked[data.metadata[index].meta_id],
            parent_metadata_id: checked[data.metadata[index].meta_id]
                ? selectedParentMetadata[data.metadata[index].meta_id]
                : null
        };
        let isAddMode = false;
        if (data.metadata[index]?.meta_id) {
            API = updateMetadata;
            isAddMode = false;
        } else {
            API = createMetadata;
            isAddMode = true;
        }

        API(token, payload)
            .then((response) => {
                // Check if the metadata creation was successful and we have a meta_id
                if (
                    (response.status || response?.response?.data?.code) === 200
                ) {
                    // If there is an image to upload, proceed with uploading

                    if (
                        data.metadata[index].imageUpload &&
                        Object.keys(data.metadata[index].imageUpload)?.length
                    ) {
                        // Construct FormData for file upload
                        const formData = new FormData();
                        formData.append(
                            'id',
                            response?.data?.data?.meta_id ||
                                data?.metadata[index]?.meta_id
                        ); // Pass the meta_id from the response
                        formData.append('type', 'METADATA');
                        formData.append(
                            'files',
                            data.metadata[index].imageUpload
                        );

                        // Dispatch the UploadMetaData action
                        UploadMetaData(token, formData).then(
                            (uploadResponse) => {
                                console.log(
                                    'Image uploaded successfully:',
                                    uploadResponse.response
                                );
                                if (uploadResponse.type === 'success') {
                                    // Handle successful image upload
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: `Meta data ${
                                            isAddMode ? 'added' : 'updated'
                                        } successfully.`,
                                        hideAlert: true
                                    });
                                } else {
                                    // Handle image upload failure
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message:
                                            uploadResponse.response?.data
                                                ?.error ||
                                            `Failed to ${
                                                isAddMode ? 'add' : 'update'
                                            } Meta data.`,
                                        hideAlert: true,
                                        error: true
                                    });
                                }
                            }
                        );
                    } else {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: `Meta data ${
                                isAddMode ? 'added' : 'updated'
                            } successfully.`,
                            hideAlert: true
                        });
                    }
                } else {
                    // Handle metadata creation failure
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            response?.response?.data?.error ||
                            `Failed to ${
                                isAddMode ? 'add' : 'update'
                            } Meta data.`,
                        hideAlert: true,
                        error: true
                    });
                    console.error(
                        'Metadata creation failed:',
                        response.response
                    );
                }
            })
            .catch(() => {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: `Failed to ${
                        isAddMode ? 'add' : 'update'
                    } Meta data.`,
                    hideAlert: true,
                    error: true
                });
            });
    };
    const handleSwitchChange = (meta_id, checked) => {
        setIsEnabled((prevState) => ({
            ...prevState,
            [meta_id]: checked
        }));

        updateMetadata(token, { meta_id, is_enabled: checked }).then((r) => {
            if (r?.type === 'success') {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Meta data updated successfully.',
                    hideAlert: true
                });
            } else {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message:
                        r?.response?.data?.error ||
                        'Failed to update Meta data.',
                    hideAlert: true,
                    error: true
                });
            }
        });
    };
    const handleDelete = (meta_id, index) => {
        if (meta_id) {
            CommonDispatcher(OPEN_ALERT_MODAL, {
                isOpen: true,
                title: 'Delete',
                submitTitle: 'Delete',
                description: 'Are you sure you want to delete this metadata?',
                data: { disableBackdropClick: true },
                cancelTitle: 'No',
                callBack: () => {
                    DeleteMetaData(token, { meta_id })
                        .then((r) => {
                            console.log('r: ', r);
                            if (r?.type === 'success') {
                                remove(index);
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: 'Meta data removed successfully.',
                                    hideAlert: true
                                });
                            } else {
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: 'Failed to remove Meta data.',
                                    error: true
                                });
                            }
                            CommonDispatcher(OPEN_ALERT_MODAL, {
                                isOpen: false
                            });
                        })
                        .catch(() => {
                            CommonDispatcher(OPEN_ALERT_MODAL, {
                                isOpen: false
                            });
                            CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                message: 'Failed to remove Meta data.',
                                error: true
                            });
                        });
                }
            });
        } else {
            remove(index);
        }
    };
    const imageTypes = [
        { name: 'favicon', value: 'METADATA_FAVICON', size: '35x35' },
        { name: 'logo', value: 'METADATA_LOGO', size: '150x30' },
        { name: 'mobileLogo', value: 'METADATA_MOBILE_LOGO', size: '30x30' }
    ];
    const handleUpdateProfile = () => {
        if (profileDetails?.admin_id) {
            const data: any = getValues();
            const general_title = data?.general_title;
            const newuser_welcome_url = data?.newuser_welcome_url;
            const help_center_url = data?.help_center_url;
            const help_chat_value = data?.help_chat_value;

            const google_analytics_id = data?.google_analytics_id;
            const ecosystem_partner_text = data?.ecosystem_partner_text;

            UpdateClient(token, {
                client_id: profileDetails?.admin_id,
                general_title,
                newuser_welcome_url,
                help_center_url,
                help_chat_value,
                google_analytics_id,
                ecosystem_partner_text
            }).then((r) => {
                if (r?.type === 'success') {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Details updated successfully.',
                        hideAlert: true
                    });
                } else {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            r?.response?.data?.error ||
                            'Failed to update details.',
                        hideAlert: true,
                        error: true
                    });
                }
            });
        }
    };
    const isChecked = (meta_id, checked) => {
        setChecked((prevState) => ({
            ...prevState,
            [meta_id]: checked
        }));
    };
    const handleParentMetadataChange = (meta_id, value) => {
        setSelectedParentMetadata((prevState) => ({
            ...prevState,
            [meta_id]: value
        }));
    };

    return (
        <Container maxWidth="md">
            <Grid container spacing={3} className={style.uploadContainer}>
                {imageTypes.map((type) => (
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        className={style.upload}
                        key={type?.value}
                    >
                        <input
                            accept="image/*"
                            className={style.avatarInput}
                            id={`upload-${type?.value}`}
                            type="file"
                            onChange={(e) => uploadImage(e, type?.value)}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor={`upload-${type?.value}`}>
                            {imageLoading[type?.value] ? (
                                <CircularProgress />
                            ) : (
                                <Avatar
                                    src={
                                        uploadedImages[type?.value] ||
                                        'path_to_default_image'
                                    }
                                />
                            )}
                        </label>
                        <label
                            htmlFor={`upload-${type?.value}`}
                            className={style.btnUploadLabel}
                        >
                            {type?.size}
                            <Button color="primary" component="span">
                                {uploadedImages[type?.value]
                                    ? 'Change'
                                    : 'Upload'}{' '}
                                {type?.name}
                            </Button>
                        </label>
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={3}>
                {profileDetails?.admin_metadata_tab ? (
                    <>
                        <Grid
                            container
                            spacing={4}
                            className="d-flex m-auto align-items-center mb-6"
                        >
                            <Grid item md={9}>
                                <Controller
                                    control={control}
                                    name={`general_title`}
                                    render={(field) => (
                                        <RenderTextField
                                            {...field}
                                            label="General Title"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className="mt-5"
                                    onClick={handleUpdateProfile}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={4}
                            className="d-flex m-auto align-items-center mb-6"
                        >
                            <Grid item md={9}>
                                <Controller
                                    control={control}
                                    name={`help_center_url`}
                                    render={(field) => (
                                        <RenderTextField
                                            {...field}
                                            label="Help Center Url *"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className="mt-5"
                                    onClick={handleUpdateProfile}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={4}
                            className="d-flex m-auto align-items-center mb-6"
                        >
                            <Grid item md={9}>
                                <Controller
                                    control={control}
                                    name={`help_chat_value`}
                                    render={(field) => (
                                        <RenderTextField
                                            {...field}
                                            label="Help Chat Value *"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className="mt-5"
                                    onClick={handleUpdateProfile}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={4}
                            className="d-flex m-auto align-items-center mb-6"
                        >
                            <Grid item md={9}>
                                <Controller
                                    control={control}
                                    name={`newuser_welcome_url`}
                                    render={(field) => (
                                        <RenderTextField
                                            {...field}
                                            label="Welcome Url (app.xyz.com) *"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className="mt-5"
                                    onClick={handleUpdateProfile}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={4}
                            className="d-flex m-auto align-items-center mb-6"
                        >
                            <Grid item md={9}>
                                <Controller
                                    control={control}
                                    name={`google_analytics_id`}
                                    render={(field) => (
                                        <RenderTextField
                                            {...field}
                                            label="Google Analytics Measurement ID"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className="mt-5"
                                    onClick={handleUpdateProfile}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={4}
                            className="d-flex m-auto align-items-center mb-6"
                        >
                            <Grid item md={9}>
                                <Controller
                                    control={control}
                                    name={`ecosystem_partner_text`}
                                    render={(field) => (
                                        <RenderTextField
                                            {...field}
                                            label="Ecosystem Partner Text"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className="mt-5"
                                    onClick={handleUpdateProfile}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className="my-10">
                            <Grid item md={9}>
                                <Typography className="colorPurple fs-20">
                                    Meta Data List
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Button
                                    onClick={() =>
                                        CommonDispatcher(ADD_META_DATA_MODAL, {
                                            title: 'Add Ecosystem',
                                            isOpen: true,
                                            data: {
                                                id: profileDetails?.admin_id
                                            }
                                        })
                                    }
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Add ecosystem type
                                </Button>
                            </Grid>
                        </Grid>
                        {fields.map((item, index) => (
                            <Accordion key={item?.id}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <div className="d-flex justify-content-between w-100 align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-direction-column">
                                                <Typography>{`${
                                                    item?.label ||
                                                    'Create New MetaData'
                                                } `}</Typography>
                                                {item?.rank ? (
                                                    <Typography className="mt-2 colorPurple">
                                                        <b>
                                                            Rank: {item?.rank}
                                                        </b>
                                                    </Typography>
                                                ) : null}
                                            </div>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            isEnabled[
                                                                item.meta_id
                                                            ]
                                                        }
                                                        onChange={(event) =>
                                                            handleSwitchChange(
                                                                item.meta_id,
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                        color="primary"
                                                    />
                                                }
                                                className="ml-3"
                                                label="Is Enabled"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        edge="start"
                                                        checked={
                                                            checked[
                                                                item.meta_id
                                                            ]
                                                        }
                                                        tabIndex={-1}
                                                        className="mr-3"
                                                        color="primary"
                                                        disableRipple
                                                        onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            isChecked(
                                                                item?.meta_id,
                                                                e.target.checked
                                                            );
                                                        }}
                                                    />
                                                }
                                                className="ml-3"
                                                label="Is SubMenu"
                                            />
                                            {checked[item.meta_id] ? (
                                                <Controller
                                                    name={`metadata[${index}].parent_metadata_id`}
                                                    control={control}
                                                    render={(field) => (
                                                        <RenderSelectField
                                                            {...field}
                                                            dataArray={
                                                                dataArray
                                                            }
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                field.onChange(
                                                                    event
                                                                );
                                                                handleParentMetadataChange(
                                                                    item.meta_id,
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    className="w-100"
                                                />
                                            ) : null}
                                        </div>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(
                                                    item?.meta_id,
                                                    index
                                                );
                                            }}
                                            color="secondary"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <form onSubmit={(e) => onSubmit(e, index)}>
                                        <Grid
                                            container
                                            spacing={2}
                                            key={item.id}
                                            alignItems="center"
                                        >
                                            <Grid
                                                style={{ display: 'none' }}
                                                item
                                                xs={12}
                                            >
                                                <Controller
                                                    control={control}
                                                    style={{
                                                        visibility: 'hidden'
                                                    }}
                                                    name={`metadata[${index}].meta_id`}
                                                    render={(field) => (
                                                        <RenderTextField
                                                            {...field}
                                                            label=""
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    control={control}
                                                    name={`metadata[${index}].label`}
                                                    render={(field) => (
                                                        <RenderTextField
                                                            {...field}
                                                            label="Label *"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    control={control}
                                                    name={`metadata[${index}].identifier`}
                                                    render={(field) => (
                                                        <RenderTextField
                                                            {...field}
                                                            startAdornment={'/'}
                                                            label="Identifier"
                                                            variant="outlined"
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                e.target.value =
                                                                    e?.target?.value.toLowerCase();
                                                                field?.onChange(
                                                                    e
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name={`metadata[${index}].is_external`}
                                                    control={control}
                                                    render={({
                                                        onChange,
                                                        value
                                                    }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        );
                                                                        if (
                                                                            !e
                                                                                .target
                                                                                .checked
                                                                        ) {
                                                                            setValue(
                                                                                `metadata[${index}].client_url`,
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                    checked={
                                                                        true
                                                                    }
                                                                />
                                                            }
                                                            disabled={true}
                                                            label="Is external"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Controller
                                                    name={`metadata[${index}].client_url`}
                                                    control={control}
                                                    render={(
                                                        field // Destructure the field object
                                                    ) => (
                                                        <RenderTextField
                                                            {...field}
                                                            label="Client URL *"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Controller
                                                    control={control}
                                                    name={`metadata[${index}].rank`}
                                                    render={(field) => (
                                                        <RenderTextField
                                                            {...field}
                                                            type="number"
                                                            label="Rank *"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Controller
                                                    control={control}
                                                    name={`metadata[${index}].title`}
                                                    render={(field) => (
                                                        <RenderTextField
                                                            {...field}
                                                            label="Title *"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    control={control}
                                                    name={`metadata[${index}].description`}
                                                    render={(field) => (
                                                        <RenderTextField
                                                            {...field}
                                                            label="Description"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    control={control}
                                                    name={`metadata[${index}].seo_keywords`}
                                                    render={(field) => (
                                                        <RenderTextField
                                                            {...field}
                                                            label="SEO Keywords"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    control={control}
                                                    name={`metadata[${index}].headline`}
                                                    render={(field) => (
                                                        <RenderTextField
                                                            {...field}
                                                            label="Headline"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    control={control}
                                                    name={`metadata[${index}].imageUpload`}
                                                    render={(field) => (
                                                        <input
                                                            accept="image/*"
                                                            style={{
                                                                display: 'none'
                                                            }}
                                                            id={`icon-button-file-${index}`}
                                                            type="file"
                                                            onChange={(e) =>
                                                                handleImageChange(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            name={`metadata[${index}].imageUpload`}
                                                        />
                                                    )}
                                                />
                                                <label
                                                    htmlFor={`icon-button-file-${index}`}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                        disabled={
                                                            isMetaDataImageUploadLoading
                                                        }
                                                    >
                                                        Upload Image
                                                    </Button>
                                                </label>

                                                {selectedImages[index] ? (
                                                    <div>
                                                        <img
                                                            src={
                                                                selectedImages[
                                                                    index
                                                                ]
                                                            }
                                                            alt={`metadata-${index}`}
                                                            className={
                                                                style.uploadedImage
                                                            }
                                                            style={{
                                                                height: '100px',
                                                                marginTop:
                                                                    '10px'
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            gutterBottom
                                                        >
                                                            Image size must be
                                                            1600x1600 pixels.
                                                        </Typography>
                                                    </div>
                                                ) : item.image ? (
                                                    <div>
                                                        <img
                                                            src={item.image}
                                                            alt={`metadata-${index}`}
                                                            className={
                                                                style.uploadedImage
                                                            }
                                                            style={{
                                                                height: '100px',
                                                                marginTop:
                                                                    '10px'
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            gutterBottom
                                                        >
                                                            Image size must be
                                                            1600x1600 pixels.
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        className="mt-2"
                                                        gutterBottom
                                                    >
                                                        Please upload an image
                                                        with the size of
                                                        1600x1600 pixels.
                                                    </Typography>
                                                )}
                                            </Grid>

                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className="d-flex align-items-center justify-content-end"
                                                >
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {item.meta_id
                                                            ? 'Update'
                                                            : 'Create'}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                        <Button
                            type="button"
                            onClick={() =>
                                append({
                                    label: '',
                                    identifier: '',
                                    title: '',
                                    description: '',
                                    seo_keywords: '',
                                    headline: '',
                                    help_chat_value: '',
                                    imageUpload: '',
                                    is_external: true,
                                    client_url: '',
                                    meta_id: ''
                                })
                            }
                            startIcon={<AddCircleIcon />}
                            variant="contained"
                            color="primary"
                            className="mt-5"
                        >
                            Add New Metadata
                        </Button>
                    </>
                ) : null}
            </Grid>
        </Container>
    );
};
export default connect(null, {
    fetchMetadataList,
    GetSingleClient,
    UpdateClient,
    createMetadata,
    CommonDispatcher,
    DeleteMetaData,
    updateMetadata,
    UploadMetaData
})(MetaDataForm);
