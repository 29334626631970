import {
    ADD_CLIENT,
    CHANGE_PASSWORD_MODAL,
    FIRM_LIST_MODAL,
    OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL,
    OPEN_ALERT_MODAL,
    OPEN_DEAL_UPDATE_MODAL,
    OPEN_EXTEND_CLOSING_DATE_MODAL,
    OPEN_EXTEND_SUBSCRIPTIONS_MODAL,
    OPEN_INVESTORS_MODAL,
    SESSIONS_MODAL,
    AFFILIATED_MODAL,
    ADD_MARKETING_MODAL,
    SHOW_USER_MODAL,
    TOGGLE_CAP_TABLE_HISTORY_MODAL,
    TOGGLE_CHANGE_OWNERSHIP_MODAL,
    TOGGLE_DISCLOSE_PRE_MONEY_MODAL,
    TOGGLE_SUBMIT_ALERT_MODAL,
    IMPORT_FIRM_MODAL,
    ADD_ADDRESS_BOOK_NOTE_MODAL,
    ADD_SUPPORT_USER_MODAL,
    ADD_META_DATA_MODAL,
    CHANGE_USER_EMAIL_MODAL,
    BROAD_CAST_USERS_MODAL,
    BROAD_CAST_MESSAGES_MODAL,
    OPEN_USER_SIDE_MODAL,
    ADD_NEW_USER_SIDE_MODAL,
    ADD_BANNER_MODAL
} from './actionTypes';

const obj = {
    isOpen: false,
    config: {},
    data: {},
    callBack: (flag, data) => {}
};

const initialValues = {
    alertModal: { ...obj },
    changeOwnershipModal: { ...obj },
    extendSubscriptionModal: { ...obj },
    activateSubscriptionModal: { ...obj },
    extendClosingDateModal: { ...obj },
    dealUpdateModal: { ...obj },
    investorsModal: { ...obj },
    capTableHistoryModal: { ...obj },
    submitAlertModal: { ...obj },
    disclosePremoneyModal: { ...obj },
    addClient: { ...obj },
    firmListModal: { ...obj },
    showUserModal: { ...obj },
    addAdressbookNoteModal: { ...obj },
    importFirmModal: { ...obj },
    sessionsModal: { ...obj },
    affiliatedModal: { ...obj },
    addBannerModal: { ...obj },
    addSuportUserModal: { ...obj },
    addMarketingEmailModal: { ...obj },
    addMetaDataModal: { ...obj },
    changePasswordModal: { ...obj },
    broadCastMessageModal: { ...obj },
    addNewUserSideModal: { ...obj, isCallGetApi: false },
    openUserSideModal: { ...obj },
    broadCastUserModal: { ...obj },

    changeUserEmailModal: { ...obj }
};

export const ModalReducer = (state = initialValues, action) => {
    switch (action.type) {
        case SESSIONS_MODAL:
            return Object.assign({}, state, {
                sessionsModal: action.payload
            });
        case AFFILIATED_MODAL:
            return Object.assign({}, state, {
                affiliatedModal: action.payload
            });
        case ADD_BANNER_MODAL:
            return Object.assign({}, state, {
                addBannerModal: action.payload
            });
        case ADD_SUPPORT_USER_MODAL:
            return Object.assign({}, state, {
                addSuportUserModal: action.payload
            });
        case ADD_MARKETING_MODAL:
            return Object.assign({}, state, {
                addMarketingEmailModal: action.payload
            });
        case ADD_META_DATA_MODAL:
            return Object.assign({}, state, {
                addMetaDataModal: action.payload
            });
        case CHANGE_PASSWORD_MODAL:
            return Object.assign({}, state, {
                changePasswordModal: action.payload
            });
        case BROAD_CAST_MESSAGES_MODAL:
            return Object.assign({}, state, {
                broadCastMessageModal: action.payload
            });
        case OPEN_USER_SIDE_MODAL:
            return Object.assign({}, state, {
                openUserSideModal: action.payload
            });
        case ADD_NEW_USER_SIDE_MODAL:
            return Object.assign({}, state, {
                addNewUserSideModal: action.payload
            });
        case BROAD_CAST_USERS_MODAL:
            return Object.assign({}, state, {
                broadCastUserModal: action.payload
            });
        case CHANGE_USER_EMAIL_MODAL:
            return Object.assign({}, state, {
                changeUserEmailModal: action.payload
            });
        case FIRM_LIST_MODAL:
            return Object.assign({}, state, {
                firmListModal: action.payload
            });
        case SHOW_USER_MODAL:
            return Object.assign({}, state, {
                showUserModal: action.payload
            });
        case ADD_ADDRESS_BOOK_NOTE_MODAL:
            return Object.assign({}, state, {
                addAdressbookNoteModal: action.payload
            });
        case IMPORT_FIRM_MODAL:
            return Object.assign({}, state, {
                importFirmModal: action.payload
            });
        case OPEN_ALERT_MODAL:
            return Object.assign({}, state, { alertModal: action.payload });
        case TOGGLE_CHANGE_OWNERSHIP_MODAL:
            return Object.assign({}, state, {
                changeOwnershipModal: action.payload
            });
        case ADD_CLIENT:
            return Object.assign({}, state, {
                addClient: action.payload
            });
        case OPEN_EXTEND_SUBSCRIPTIONS_MODAL:
            return Object.assign({}, state, {
                extendSubscriptionModal: action.payload
            });
        case OPEN_EXTEND_CLOSING_DATE_MODAL:
            return Object.assign({}, state, {
                extendClosingDateModal: action.payload
            });
        case OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL:
            return Object.assign({}, state, {
                activateSubscriptionModal: action.payload
            });
        case OPEN_DEAL_UPDATE_MODAL:
            return Object.assign({}, state, {
                dealUpdateModal: action.payload
            });
        case OPEN_INVESTORS_MODAL:
            return Object.assign({}, state, { investorsModal: action.payload });
        case TOGGLE_CAP_TABLE_HISTORY_MODAL:
            return Object.assign({}, state, {
                capTableHistoryModal: action.payload
            });
        case TOGGLE_SUBMIT_ALERT_MODAL:
            return Object.assign({}, state, {
                submitAlertModal: action.payload
            });
        case TOGGLE_DISCLOSE_PRE_MONEY_MODAL:
            return Object.assign({}, state, {
                disclosePremoneyModal: action.payload
            });
        default:
            return state;
    }
};
