import axios from 'axios';
import { API_PATH } from '../../constants';

export const GetValuesByCategory = (token, category) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${API_PATH}/admin/getValuesByCategory`,
            category,
            {
                headers: { Authorization: token }
            }
        );

        return response;
    } catch (error) {
        console.error('Error creating metadata:', error);
        return { type: 'failed', response: error?.response };
    }
};
export const AddOrUpdateCategory =
    (token, categoryData) => async (dispatch) => {
        try {
            const url = `${API_PATH}/admin/updateOrAddValue`; // API endpoint for add/update

            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    category: categoryData.category,
                    id: categoryData.id || null,
                    label: categoryData.label,
                    rank: categoryData.rank,
                    url_identifier: categoryData.url_identifier
                },
                headers: { Authorization: token }
            });

            // dispatch({
            //     type: categoryData.id ? UPDATE_METADATA : CREATE_METADATA,
            //     payload: response.data?.data
            // });

            return { type: 'success', response };
        } catch (error) {
            console.error('Error in add/update category:', error);
            return { type: 'failed', response: error?.response };
        }
    };
